import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import authFetch from "../axios";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import RecordDetails from "../components/recordDetails";
const SingleStill = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [reg, setReg] = useState({});
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  useEffect(() => {
    const getStatz = async () => {
      setLoading(true);
      try {
        const statistics = await authFetch.get(
          `/dcr/registrations/stillbirth/${id}`
        );
        setLoading(false);
        setReg(statistics.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getStatz();
  }, [id]);
  if (loading) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }
  return (
    <>
      <div>
        <div>
          <Button
            label="Go back"
            icon="pi pi-arrow-left"
            className="p-button-sm my-2"
            onClick={goBack}
          />
        </div>
        <div className="flex flex-column gap-5">
          <article className={`bg-green-600 text-white text-sm p-2`}>
            <span className="text-sm font-bold">Registration Details</span>
          </article>

          <RecordDetails
            records={reg}
            fieldsToDisplay={[
              "reg_center_name",
              "deceased_village",
              "date_of_registration",
              "state",
              "local_government_area",
            ]}
            fieldProcessors={{
              reg_center_name: (reg) =>
                `${reg?.registration_centerData?.Reg_Center_Name ?? ""}`,
              deceased_village: (reg) => `${reg?.deceased_village ?? "----"}`,
              date_of_registration: (reg) =>
                `${reg?.deceased_date_of_delivery ?? "----"}`,
              state: (reg) => `${reg?.birth_stateData?.State_Name ?? "----"}`,
              local_government_area: (reg) =>
                `${reg?.birth_lgaData?.LGA_Name ?? "----"}`,
            }}
          />

          <RecordDetails
            type="Deceased"
            records={reg}
            fieldsToDisplay={["type_of_birth", "gender", "place_of_birth"]}
            fieldProcessors={{
              type_of_birth: (reg) =>
                `${reg?.birth_typeData?.Description ?? "----"}`,
              gender: (reg) => `${reg?.deceased_sexData?.gender ?? "----"}`,
              place_of_birth: (reg) =>
                `${reg?.birth_placeData?.BirthPlace_Desc ?? "----"}`,
            }}
          />
          <RecordDetails
            type="Mother"
            records={reg}
            fieldsToDisplay={["name", "mother_country", "nationality"]}
            fieldProcessors={{
              name: (reg) => `${reg?.mother_fullname ?? "----"}`,
              mother_country: (reg) =>
                `${reg?.mother_countryData?.Country_Name ?? "----"}`,
              nationality: (reg) =>
                `${reg?.mother_nationalityData?.Country_Name ?? "----"}`,
            }}
          />

          <RecordDetails
            type="Informant"
            records={reg}
            fieldsToDisplay={[
              "name",
              "address",
              "relationship_with_mother",
              "lga",
            ]}
            fieldProcessors={{
              name: (reg) => `${reg?.informant_fullname ?? "----"}`,
              relationship_with_mother: (reg) =>
                `${
                  reg?.informant_relationship_with_motherData?.Description ??
                  "----"
                }`,
              address: (reg) => `${reg?.informant_street_name ?? "----"}`,
              lga: (reg) => `${reg?.informant_lgaData?.LGA_Name ?? "----"}`,
            }}
          />

          <article className="shadow-lg bg-white  text-sm px-4 py-2 box-shadow-css">
            <h2 className="text-sm text-black-alpha-90 font-bold">
              <span className="border-bottom-2 border-blue-600">Uploaded </span>{" "}
              Documents
            </h2>
            <section
              className="flex flex-wrap gap-3 w-full"
              style={{ wordBreak: "break-word" }}
            >
              {reg?.SupportingDocument?.length > 0 ? (
                reg.SupportingDocument?.filter((doc) => doc.file !== null).map(
                  (filteredDoc) => (
                    <div
                      style={{ maxWidth: "30%" }}
                      className="flex flex-wrap gap-3 w-full"
                      key={filteredDoc.type}
                    >
                      {filteredDoc.mimeType ? (
                        <>
                          {filteredDoc.mimeType === "application/pdf" ? (
                            <div className="card shadow-lg h-[200px] flex items-center px-2 justify-center rounded-md overflow-hidden">
                              <div className="gap-3 block ">
                                <a
                                  className="text-xs"
                                  href={filteredDoc.file}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="pi pi-file-pdf h-20 text-center w-full w-2 text-red-700" />
                                  {filteredDoc.type?.replace("-", " ")}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div className="shadow-lg w-full rounded-md overflow-hidden">
                              <p className="w-full capitalize">
                                <span className="font-bold">
                                  Document Type:{" "}
                                </span>{" "}
                                {filteredDoc.type?.replace("-", " ")}
                              </p>
                              <Image
                                src={filteredDoc.file}
                                zoomSrc={filteredDoc.file}
                                alt={filteredDoc.type}
                                preview
                                width="100%"
                                height="300px"
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div
                            style={{ maxWidth: "30%" }}
                            className="shadow-lg rounded-md overflow-hidden"
                          >
                            <p className="w-full capitalize">
                              <span className="font-bold">Document Type: </span>{" "}
                              {filteredDoc.type?.replace("-", " ")}
                            </p>
                            <Image
                              src={filteredDoc.file}
                              zoomSrc={filteredDoc.file}
                              alt={filteredDoc.type}
                              preview
                              width="100%"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )
                )
              ) : (
                <p>No supporting documents available.</p>
              )}
            </section>
          </article>
        </div>
      </div>
    </>
  );
};

export default SingleStill;
