import React from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { handleChange, clearFilters } from "../redux/deathSlice";
import { typeOptions } from "../utils";
import { selfOptions } from "../helpers/constants";
const Filter2 = () => {
  const dispatch = useDispatch();

  const { search, type, self } = useSelector((state) => state.death);

  const clearForm = () => {
    dispatch(clearFilters());
  };

  return (
    <div className=" hidden md:flex justify-content-between">
      <div className=" w-full md:flex justify-between align-items-end gap-3">
        <div className="w-full md:flex gap-3 align-items-end">
          <div className="flex flex-column">
            <InputText
              value={search}
              onChange={(e) =>
                dispatch(
                  handleChange({ name: "search", value: e.target.value })
                )
              }
              placeholder="Keyword Search"
            />
          </div>

          <div className="">
            <div className="flex  gap-2">
              <div className="flex flex-column">
                <label htmlFor="integeronly" className="mb-2">
                  Record Status
                </label>

                <Dropdown
                  value={type}
                  options={typeOptions}
                  onChange={(e) =>
                    dispatch(handleChange({ name: "type", value: e.value }))
                  }
                  className="w-full md:w-14rem"
                  placeholder="Select Status"
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="integeronly" className="mb-2">
                  Record Type
                </label>

                <Dropdown
                  value={self}
                  options={selfOptions}
                  onChange={(e) =>
                    dispatch(handleChange({ name: "self", value: e.value }))
                  }
                  className="w-full md:w-14rem"
                  placeholder="Select type"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-2">
          <Button
            label="Reset Filter"
            className="p-button-warning"
            onClick={clearForm}
          />
        </div>
      </div>
    </div>
  );
};

export default Filter2;
