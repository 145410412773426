import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import { USER_ROLE } from "../utils";
const ProtectedRouteAdmin = ({ children }) => {

  const { userInfo } = useSelector((state) => state.user);
  // const userInfo = JSON.parse(localStorage.getItem('user-birth'))
  // const userInfo={name:'true',
  // isAdmin:true};
  // const newUser= JSON.parse(userInfo));
  // console.log(JSON.parse(userInfo?.Role_ID));

  if (!userInfo) {
    return (
      <div className="flex justify-content-center align-items-center h-screen">
          <Loading />
      </div>
    );
  }
  return userInfo && userInfo.Role_ID === USER_ROLE.STATE_DIRECTOR ? (
    children
  ) : userInfo && userInfo.Role_ID === USER_ROLE.DCR ? (
    children
  ) : userInfo && userInfo.Role_ID === USER_ROLE.ADMIN ? (
    children
  ) : userInfo && userInfo.Role_ID === USER_ROLE.REGISTRAR ? (
    children
  ) : (
    <Navigate to="/unauth" />
  );
};

export default ProtectedRouteAdmin;
