import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";
import { getRegs } from "../redux/apiCalls";
import { editz } from "../redux/apiCalls";
import authFetch from "../axios";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import "./admin.css";
import { useReactToPrint } from "react-to-print";
const SingleApplication = () => {
  const [certLoading, setCertLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [queryModal, setQueryModal] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);

  const [value, setValue] = useState("");
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [printVisible, setprintVisible] = useState(false);
  const toast = useRef();
  const [img, setImg] = useState("");
  const { isLoading, reg, isEditing, isEdited } = useSelector(
    (state) => state.attest
  );
  const ed = (id) => {
    editz(id, dispatch);
  };
  const printcomponentRef = useRef();
  const viewBirth = async () => {
    setCertLoading(true);
    // /cert/birth/${reg?.Certificate_No}
    setLoading(true);
    try {
      console.log(reg?.attestation_number);
      const res = await authFetch.get(
        `/cert/attestation/${reg?.attestation_number}`
      );
      console.log(res.data);
      setLoading(false);
      setCertLoading(false);
      setImg(res.data);
      setprintVisible(true);
    } catch (error) {
      setLoading(false);
      setCertLoading(false);
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${error.response.data.message}`,
      });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printcomponentRef.current,
  });

  const print = async () => {
    // /cert/birth/${reg?.Certificate_No}
    setLoading(true);
    setPrintLoading(true);

    try {
      console.log(reg?.attestation_number);
      const res = await authFetch.patch(
        `/state-director/birth-attestation/print/${reg?.id}`
      );
      console.log(res);
      setLoading(false);
      setPrintLoading(false);

      if (res.data.success === true) {
        setPrintLoading(false);
        handlePrint();

        // window.print();
      } else {
        setPrintLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${res.data.message}`,
        });
      }
    } catch (error) {
      setLoading(false);
      setPrintLoading(false);

      // console.log(error);
    
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${error.response.data.message}`,
      });
    }
  };

    // eslint-disable-next-line
  const share = async () => {
    // /cert/birth/${reg?.Certificate_No}
    setLoading(true);
    try {
      console.log(reg?.attestation_number);
      const res = await authFetch.post(`/cert/share`, {});
      console.log(res.data);
      setLoading(false);
      setImg(res.data);
      setprintVisible(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${error.response.data.message}`,
      });
    }
  };

  const app = async (id) => {
    setQueryLoading(true);

    if (!value) {
      alert("please input a reason");
      return;
    }
    try {
      const res = await authFetch.get(`/dcr/registrations/death/reject`, {params: {id: parseInt(id), reason: value}});
      console.log(res.data);
      setQueryLoading(false);

      if (res.data.success === true) {
        toast.success("Registration queried", {
          position: "top-left",
        });
        window.location.reload();
      } else {
        toast.success("Registration not queried", {
          position: "top-left",
        });
      }
    } catch (error) {
      toast.error("Registration not queried.retry", {
        position: "top-left",
      });
      console.log(error);
      setQueryLoading(false);
      setQueryModal(false);
    }
  };
  useEffect(() => {
    getRegs(dispatch, id);
    // console.log(reg)
  }, [dispatch, id, isEdited]);

  if (isLoading) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }
  return (
    <div>
      <div className="surface-card p-4 shadow-2 border-round">
        <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm"
          onClick={goBack}
        />
        <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
          Particulars of Applicant
        </div>
        <div className="flex flex-column lg:flex-row flex-wrap lg:justify-content-between border-bottom-3 border-green-500 gap-7">
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">
              National Identification Number *
            </div>
            <div className="text-900 text-md mb-5 ">
              {reg?.personData?.nin ?? ""}
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">Status</div>
            <div className="text-900 text-md mb-5 ">
              {reg?.approval_status === "0"
                ? "pending"
                : reg?.approval_status === "1"
                ? "Approved"
                : reg?.approval_status === "2"
                ? "Queried"
                : ""}
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">
              Surname Of Applicant *
            </div>
            <div
              className="text-900 text-md mb-5 "
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.personData?.surname ?? ""}
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">Firstname</div>
            <div
              className="text-900 text-md mb-5 "
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.personData?.firstname ?? ""}
            </div>
          </div>

          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">Sex</div>
            <div className="text-900 text-md mb-5 ">
              {reg?.personData?.genderData?.gender ?? ""}
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">
              Date Of Birth *
            </div>
            <div className="text-900 text-md mb-5 ">
              {reg?.personData?.birth_date ?? ""}
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">
              Marital Status *
            </div>
            <div className="text-900 text-md mb-5 ">
              {reg?.marital_statusData?.Status_Desc ?? ""}
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">
              Nationality *
            </div>
            <div className="text-900 text-md mb-5 ">
              {reg?.personData?.birth_countryData?.Country_Name ?? ""}
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">
              State Of Origin *
            </div>
            <div className="text-900 text-md mb-5 ">
              {reg?.personData?.origin_stateData?.State_Name ?? ""}
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">
              Local Government Area Of Origin *
            </div>
            <div className="text-900 text-md mb-5 ">
              {reg?.personData?.origin_lgaData?.LGA_Name ?? ""}
            </div>
          </div>

          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">email</div>
            <div
              className="text-900 text-md mb-5 "
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.applicant_email ?? ""}
            </div>
          </div>
          <div className="flex flex-column">
            <div className="font-medium text-md text-500 mb-3">
              phone number
            </div>
            <div className="text-900 text-md mb-5 ">
              {reg?.applicant_phone ?? ""}
            </div>
          </div>
        </div>
      </div>
      <div className="surface-card p-4 shadow-2 border-round my-2">
        <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
            Additional Information
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">
              State (Place Of Birth) *
            </div>
            <div className="text-900">
              {reg?.personData?.birth_stateData?.State_Name ?? ""}
            </div>
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">
              Local Government Area (Place Of Birth) *
            </div>
            <div className="text-900">
              {reg?.personData?.birth_lgaData?.LGA_Name ?? ""}
            </div>
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">requester_name</div>

            <div className="text-900">{reg?.requester_name ?? ""}</div>
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">requester_address</div>

            <div
              className="text-900"
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.requester_address ?? ""}
            </div>
          </div>
          {/* <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Type of birth</div>
            <div className="text-900">{reg&&reg.Birth_typeData?.Description}</div>
           
        </div> */}
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">
              request required for
            </div>
            <div className="text-900">{reg?.request_required_for ?? ""}</div>
          </div>
        </div>

        <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
            Particulars of Mother
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Firstname</div>
            <div
              className="text-900"
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.mother_firstname ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Surname</div>
            <div
              className="text-900"
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.mother_surname ?? ""} 
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Town</div>
            <div
              className="text-900"
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.mother_town ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">STATE OF ORIGIN</div>
            <div
              className="text-900"
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.mother_stateData?.State_Name ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">lga</div>
            <div
              className="text-900"
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.mother_lgaData?.LGA_Name ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          {/* <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">OCCUPATION</div>
            <div className="text-900">{reg&&reg.Mother?.OccupationData?}</div>
            
        </div> */}
        </div>
        <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
            Particulars of father
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Firstname</div>
            <div
              className="text-900"
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.father_firstname ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Surname</div>
            <div
              className="text-900"
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.father_surname ?? ""} 
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Town</div>
            <div
              className="text-900"
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.father_town ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">STATE OF ORIGIN</div>
            <div
              className="text-900"
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.father_stateData?.State_Name ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">lga</div>
            <div
              className="text-900"
              style={{
                maxWidth: "40em",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                wordWrap: " break-word",
              }}
            >
              {reg?.father_lgaData?.LGA_Name ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
        </div>
        {/* <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className='col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold '>Particulars of informant</div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">{reg&&reg.Informant?.informant_names}</div>
           
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Phone</div>
            <div className="text-900">{reg&&reg.Informant?.Phone_No}</div>
           
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">NATIONAL ID NUMBER</div>
            <div className="text-900">{reg&&reg.Informant?.NIN}</div>
            
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Address</div>
            <div className="text-900">{reg&&reg.Informant?.Address}</div>
            
        </div>
       
    </div> */}
        <Toast ref={toast} />
        {reg && reg.approval_status === "0" && (
          <Button
            label="Approve"
            className="p-button-success my-2"
            loading={isEditing}
            onClick={() => ed(id)}
          />
        )}

        {reg && reg.approval_status === "1" && (
          <Button
            label="View certificate"
            className="p-button-success my-2"
            onClick={viewBirth}
            loading={certLoading}
          />
        )}

        {/* {reg &&reg.approval_status===1&&
    <Button label="Print certificate" className="p-button-success my-2"  />
} */}

        {/* {reg &&reg.approval_status===1&&
    <Button label="Share certificate" className="p-button-success my-2"  />
} */}

        {/* {(reg?.approval_status === "0" || reg?.approval_status === "1") && (
          <Button
            label="Query"
            className="p-button-warning my-2 ml-3"
            onClick={() => setQueryModal(true)}
          />
        )} */}
      </div>
      <Dialog
        header="Query"
        visible={queryModal}
        style={{ width: "30vw" }}
        onHide={() => setQueryModal(false)}
      >
        <div className="card flex justify-content-center">
          <InputTextarea
            value={value}
            placeholder="reason for query"
            onChange={(e) => setValue(e.target.value)}
            rows={5}
            cols={30}
          />
        </div>
        <Button
          label="Submit"
          loading={queryLoading}
          className="p-button-warning my-2 px-3 ml-3"
          onClick={() => app(id)}
        />
      </Dialog>

      <Dialog
        header="Certificate"
        visible={printVisible}
        style={{ width: "70vw" }}
        onHide={() => setprintVisible(false)}
      >
        <img
          src={`data:image/png;base64,${img} `}
          className="w-full to-print"
          alt=""
          ref={printcomponentRef}
        />
        {/* <Button
          label="Share certificate"
          className="p-button-success my-2"
          onClick={share}
        /> */}
        <Button
          loading={printLoading}
          label="Print certificate"
          className="p-button-success my-2"
          onClick={print}
        />
      </Dialog>
    </div>
  );
};

export default SingleApplication;
