/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { bulkPrintCertificate, getBirthRegz } from "../redux/apiCalls";
import Filter from "../components/Filter";
import { changePage, uploadCertificates } from "../redux/birthSlice";
import naija from "../assets/images/ci.svg";
import authFetch from "../axios";
import {
  SerialNumberTemplate,
  printStatusTemplate,
  regTypeFormat,
  statusFormat,
} from "../utils";
import { useTableSerial } from "../helpers/hooks/useTableSerial";
import { RecType } from "../helpers/constants";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { useDebounce } from "../helpers/hooks/useDebounce";

const AdminVerified = () => {
  const [rez, setRez] = useState({});
  const dispatch = useDispatch();
  const [basicFirst, setBasicFirst] = useState(0);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    dispatch(changePage(event.page + 1));
  };
  const [bulkLoading, setBulkLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const {
    isLoading,
    search,
    result_per_page,
    page,
    registerations,
    numPages,
    type,
    self,
    printStatus,
  } = useSelector((state) => state.birth);
  const navigate = useNavigate();
  const { value: debouncedValue } = useDebounce(search, 1000);

  useEffect(() => {
    const getStatz = async () => {
      try {
        const statistics = await authFetch.get("/birth-registration/stats");
        setRez(statistics.data);
      } catch (error) {
        console.log(error);
      }
    };

    getStatz();
    getBirthRegz(
      dispatch,
      debouncedValue,
      result_per_page,
      page,
      type,
      self,
      printStatus
    );
  }, [
    dispatch,
    debouncedValue,
    result_per_page,
    page,
    type,
    self,
    printStatus,
  ]);

  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`/singleBirth/${rowData.Birth_Reg_ID}`}
      >
        VIEW
      </Link>
    );
  };

  const firstName = (rowData) => {
    return (
      <p className={`font-bold`}>
        {`${rowData.childData?.surname ?? ""} ${
          rowData.childData?.firstname ?? ""
        } ${rowData.childData?.middle_name ?? ""} `}
      </p>
    );
  };

  const lastName = (rowData) => {
    return (
      <p className={`font-bold`}>{`${rowData.childData?.birth_date ?? ""}`}</p>
    );
  };

  const renderHeader1 = () => {
    return (
      <div className="flex gap-3 align-items-end	">
        <Filter />{" "}
        <div>
          {printStatus === RecType.Unprinted && (
            <>
              {selectedProducts?.length > 0 && (
                <Button
                  type="button"
                  className="p-button-success mr-2"
                  label="Bulk Print"
                  onClick={() => {
                    setShowBulkModal(true);
                  }}
                  loading={bulkLoading}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };
  const header1 = renderHeader1();
  const { serials } = useTableSerial({
    data: registerations,
    currentPage: page,
    pageSize: result_per_page,
  });
  const bulkPrint = async () => {
    setBulkLoading(true);
    const print_ids = selectedProducts?.map((entry) => entry.Birth_Reg_ID);
    try {
      const certificateResponse = await bulkPrintCertificate(print_ids);
      setBulkLoading(false);
      dispatch(uploadCertificates(certificateResponse?.certificates));
      if (certificateResponse?.certificates?.length < 1) {
        toast.error("Document(s) has either being printed or not approved", {
          position: "top-left",
        });
        return;
      }
      navigate("/birth/bulk-print");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="grid my-3">
        <div className="col-12 " style={{ backgroundColor: "#10A6AB" }}>
          <p className="text-white m-0 text-sm font-bold">
            Total Registrations:{rez?.total ?? ""}
          </p>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className=" shadow-2 p-3 border-round text-white"
            style={{ background: "#2F96FB" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Pendings Approvals
                </span>
                <div className=" font-bold text-xl">{rez?.Pending ?? ""}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className=" shadow-2 p-3 border-round text-white"
            style={{ background: "#FEA93B" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Approved Registrations
                </span>
                <div className=" font-bold text-xl"> {rez?.Approved ?? ""}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className=" shadow-2 p-3 border-round text-white"
            style={{ background: "#2F96FB" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Queried Records
                </span>
                <div className=" font-bold text-xl">{rez?.Queried}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className="shadow-2 p-3 border-round text-white"
            style={{ background: "#0D5094" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Printed Certificates
                </span>
                <div className=" font-bold text-xl">{rez?.Printed ?? ""}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 lg:col-12">
          <div className="card border-round shadow-2 p-3 ">
            <div className="mb-3 flex align-items-center justify-content-between p-3">
              <span className="text-xl font-medium text-900">
                {" "}
                Registration List
              </span>
            </div>
            <DataTable
              value={registerations}
              selectionMode="checkbox"
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              loading={isLoading}
              stripedRows
              responsiveLayout="stack"
              header={header1}
            >
              {printStatus === RecType.Unprinted &&
                type === RecType.Approved && (
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                  />
                )}
              <Column
                field="id"
                header="S/N"
                body={(cell) =>
                  SerialNumberTemplate(cell, serials, registerations)
                }
              ></Column>
              <Column body={firstName} header="Name"></Column>
              <Column body={lastName} header="Date of birth"></Column>
              <Column
                field="Certificate_No"
                header="certificate number"
              ></Column>
              <Column
                field=""
                header="Print Status"
                body={(rowData) => printStatusTemplate(rowData?.Print_Status)}
              ></Column>
              <Column
                body={(rowData) => regTypeFormat(rowData?.self)}
                header="Registration Type"
              ></Column>
              <Column
                body={(rowData) => statusFormat(rowData?.Approval_Status)}
                header="Status"
              ></Column>
              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={basicFirst}
              rows={result_per_page}
              totalRecords={numPages}
              onPageChange={onBasicPageChange}
            ></Paginator>
          </div>
        </div>
      </div>

      <Dialog
        header="NOTICE!!!"
        visible={showBulkModal}
        style={{ width: "40vw", color: "red" }}
        headerClassName="uppercase text-red-500 "
        onHide={() => setShowBulkModal(false)}
      >
        <p>
          Kindly note that clicking the{" "}
          <span className="font-bold text-green-500">"Continue"</span> button
          will mark the selected certificates as printed. If you wish to review
          the selected certificates, click{" "}
          <span className="font-bold text-red-700"> "Cancel"</span> to return to
          the page
        </p>
        <div className=" flex justify-content-end">
          <Button
            label="Cancel"
            onClick={() => {
              setShowBulkModal(false);
            }}
            className="p-button-danger  mr-2"
          />
          <Button
            type="button"
            className="p-button-success ml-3"
            label="Continue"
            onClick={bulkPrint}
            loading={bulkLoading}
          />
        </div>
      </Dialog>
    </>
  );
};

export default AdminVerified;
