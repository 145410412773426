import axios from "axios";
import { isArray } from "lodash";

function getErrorMessage(data) {
  if (isArray(data?.message)) {
    return data.message.join("/n");
  }
  return data.message;
}

class ErrorMessageExtractor {
  static errorParser(error) {
    console.log(error);
    if (axios.isAxiosError(error)) {
      if (error.response?.data) {
        return getErrorMessage(error.response.data);
      }
      return error.message;
    }
    return error;
  }
}

export default ErrorMessageExtractor;
