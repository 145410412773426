import { useMemo } from "react";

// GENERATE TABLE SERIAL NUMBERS
export const useTableSerial = ({ data, currentPage, pageSize }) => {
  const serials = useMemo(() => {
    const offset = (currentPage - 1) * pageSize;
    return data?.map((_, index) => index + offset + 1);
  }, [currentPage, data, pageSize]);

  // returns an array of the serial number for that particular page
  return { serials };
};
