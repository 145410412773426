import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getDeathRegz } from "../redux/apiCalls";
import Filter2 from "../components/Filter2";
import { changePage } from "../redux/deathSlice";
import naija from "../assets/images/ci.svg";
import authFetch from "../axios";
import { SerialNumberTemplate, regTypeFormat, statusFormat } from "../utils";
import { useTableSerial } from "../helpers/hooks/useTableSerial";
import { useDebounce } from "../helpers/hooks/useDebounce";
const AdminVerifiedDeath = () => {
  const [rez, setRez] = useState({});
  const dispatch = useDispatch();
  const [basicFirst, setBasicFirst] = useState(0);
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    dispatch(changePage(event.page + 1));
  };
  const {
    isLoading,
    search,
    result_per_page,
    page,
    registerations,
    numPages,
    self,
    type,
  } = useSelector((state) => state.death);
  const { value: debouncedValue } = useDebounce(search, 1000);
  useEffect(() => {
    const getStatz = async () => {
      try {
        const statistics = await authFetch.get("/death-registration/stats");
        setRez(statistics.data);
      } catch (error) {
        console.log(error);
      }
    };

    getStatz();
    getDeathRegz(dispatch, debouncedValue, result_per_page, page, type, self);
  }, [dispatch, debouncedValue, self, result_per_page, page, type]);

  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`/singleDeath/${rowData.id}`}
      >
        VIEW
      </Link>
    );
  };

  const fname = (rowData) => {
    return (
      <p className={`font-bold`}>{rowData.deceasedData?.firstname ?? ""}</p>
    );
  };
  const lname = (rowData) => {
    return <p className={`font-bold`}>{rowData.deceasedData?.surname ?? ""}</p>;
  };
  const gender = (rowData) => {
    return (
      <p className={`font-bold`}>
        {rowData.deceasedData?.genderData?.gender ?? ""}
      </p>
    );
  };

  const renderHeader1 = () => {
    return <Filter2 />;
  };
  const header1 = renderHeader1();
  const { serials } = useTableSerial({
    data: registerations,
    currentPage: page,
    pageSize: result_per_page,
  });
  return (
    <>
      <div className="grid my-3">
        <div className="col-12 " style={{ backgroundColor: "#10A6AB" }}>
          <p className="text-white m-0 text-sm font-bold">
            Total Registrations:{rez?.total}
          </p>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className=" shadow-2 p-3 border-round text-white"
            style={{ background: "#2F96FB" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Pending Approvals
                </span>
                <div className=" font-bold text-xl">{rez?.Pending}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className=" shadow-2 p-3 border-round text-white"
            style={{ background: "#FEA93B" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Approved Registrations
                </span>
                <div className=" font-bold text-xl"> {rez?.Approved}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className=" shadow-2 p-3 border-round text-white"
            style={{ background: "#2F96FB" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Queried Records
                </span>
                <div className=" font-bold text-xl">{rez?.Queried}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className="shadow-2 p-3 border-round text-white"
            style={{ background: "#0D5094" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Printed Certificates
                </span>
                <div className=" font-bold text-xl">{rez?.Printed}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 lg:col-12">
          <div className="card border-round shadow-2 p-3 ">
            <div className="mb-3 flex align-items-center justify-content-between p-3">
              <span className="text-xl font-medium text-900 capitalize">
                {type} Registration List
              </span>
              <div className="flex align-items-center export-buttons"></div>
            </div>
            <DataTable
              value={registerations}
              //  ref={dt}
              loading={isLoading}
              stripedRows
              responsiveLayout="stack"
              header={header1}
            >
              <Column
                field="id"
                header="S/N"
                body={(cell) =>
                  SerialNumberTemplate(cell, serials, registerations)
                }
              ></Column>
              <Column body={fname} header="first Name"></Column>
              <Column body={lname} header="surname"></Column>
              <Column body={gender} header="Gender"></Column>
              <Column
                field="death_certificate_number"
                header="Certificate number"
              />
              <Column
                body={(rowData) => regTypeFormat(rowData?.self)}
                header="Registration Type"
              ></Column>
              <Column
                body={(rowData) => statusFormat(rowData?.approved_status)}
                header="Status"
              ></Column>
              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={basicFirst}
              rows={result_per_page}
              totalRecords={numPages}
              onPageChange={onBasicPageChange}
            ></Paginator>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminVerifiedDeath;
