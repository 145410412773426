
import React,{useEffect} from 'react'
import { Button } from 'primereact/button';

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { ProgressSpinner } from 'primereact/progressspinner';
import { getGender2, getModsSingleBirthRegs } from '../redux/apiCalls';
import {  editModBirth} from '../redux/apiCalls';
import { Mod, getStatus,modField } from '../utils';
const SingleModifiedBirth = () => {
    const {id}=useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const {
          isLoading,
        reg ,isEditing,isEdited
        } = useSelector((state) => state.modReg);
        const ed = (id)=>{
          editModBirth(id,dispatch)
        }
      useEffect(() => {
        getModsSingleBirthRegs(dispatch,id)
    //   console.log(reg)
      }, [dispatch,id,isEdited])
      
      if(isLoading){
      return <div className='flex justify-content-center align-items-center'>
      <ProgressSpinner style={{width:'3rem',height:'3rem'}}/>
      </div>
      }
    return (
      <div> 
  
  <div className="surface-card p-4 shadow-2 border-round">
  <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm"
          onClick={goBack}
        />
  <div className='col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold '>Particulars </div>
    <div className='flex flex-column lg:flex-row flex-wrap lg:justify-content-between border-bottom-3 border-green-500 gap-3'>
      <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">certificate Number *</div>
      <div className="text-900 text-md mb-5 ">{reg&&reg.Certificate_No}</div>
    </div>
    {/* <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">Status</div>
      <div className="text-900 text-md mb-5 ">{reg &&reg.approval_status===0?'pending':reg &&reg.approval_status===1?'Approved':''}</div>
    </div> */}
    <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">Surname</div>
      <div className="text-900 text-md mb-5 ">{reg?.childData?.surname??""}</div>
    </div>
    <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">Firstname</div>
      <div className="text-900 text-md mb-5 ">{reg?.childData?.firstname??""}</div>
    </div>
    <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">Status</div>
      <div className="text-900 text-md mb-5 ">{getStatus(reg?.Modified_Status)}</div>
    </div>

    {/* <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">MMiddlename</div>
      <div className="text-900 text-md mb-5 ">{reg&&reg.middlename}</div>
    </div> */}
  
    {/* <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">Sex</div>
      <div className="text-900 text-md mb-5 ">{reg&&reg.sexData?.Gender}</div>
    </div>
    <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">Date Of Birth *</div>
      <div className="text-900 text-md mb-5 ">{reg&&reg.date_of_birth}</div>
    </div>
    <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">Marital Status *</div>
      <div className="text-900 text-md mb-5 ">{reg&&reg.marital_statusData?.Status_Desc}</div>
    </div>
    <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">Nationality *</div>
      <div className="text-900 text-md mb-5 ">{reg&&reg.country_of_birthData?.Country_Name}</div>
    </div>
    <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">State Of Origin *</div>
      <div className="text-900 text-md mb-5 ">{reg&&reg.state_of_originData?.State_Name}</div>
    </div>
    <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">Local Government Area Of Origin *</div>
      <div className="text-900 text-md mb-5 ">{reg&&reg.lga_of_originData?.LGA_Name}</div>
    </div> */}
  
    {/* <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">email</div>
      <div className="text-900 text-md mb-5 ">{reg&&reg.applicant_email}</div>
    </div>
    <div className='flex flex-column'>
      <div className="font-medium text-md text-500 mb-3">phone number</div>
      <div className="text-900 text-md mb-5 ">{reg&&reg.applicant_phone}</div>
    </div> */}
    
    
      </div>
  
    </div>
  <div className="surface-card p-4 shadow-2 border-round my-2">
  {/* <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm"
          onClick={goBack}
        /> */}
  
          <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
            <div className='col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold '>Additional Information</div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Modification Type: </div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].mod_type : "-"}</div>
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Field</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].mod_field : "-"}</div> 
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Reason for modification:</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].mod_reason : "-"}</div>
               
          </div>

          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Comments:</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].comment : "-"}</div>
               
          </div>
        
    





        
         
         
      </div>


      <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
            <div className='col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold '>Modified Information</div>
            {
  reg && reg.Modification && reg.Modification[0] ? (
    reg.Modification[0].mod_field === modField.mother_name && (
      <>
                  <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Mother previous name </div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].mother_prev_firstname : "-"}</div>
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Mother previous surname</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].mother_prev_surname : "-"}</div> 
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Mother previous middlename</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].mother_prev_middlename : "-"}</div>
               
          </div>

          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">mother firstname</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].mother_firstname : "-"}</div>
               
          </div>

          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">mother surname </div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].mother_surname : "-"}</div>
          </div>
      </>
    )
  ) : null // You might want to provide a default value if the condition is not met
}

{
  reg && reg.Modification && reg.Modification[0] ? (
    reg.Modification[0].mod_field === modField.child_name && (
      <>
                  <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child previous name </div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_prev_firstname : "-"}</div>
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child previous surname</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_prev_surname : "-"}</div> 
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child previous middlename</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_prev_middlename : "-"}</div>
               
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child previous sex</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? getGender2(reg.Modification[0].child_prev_sex) : "-"}</div> 
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child previous age</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_prev_age : "-"}</div>
               
          </div>
          <div className="col-12 md:col-2 p-3">
          <div className="text-500 font-medium mb-2">Child  name </div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_firstname : "-"}</div>
         </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child  surname</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_surname : "-"}</div> 
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child  middlename</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_middlename : "-"}</div>
               
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child  sex</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? getGender2(reg.Modification[0].child_sex) : "-"}</div> 
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child  age</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_age : "-"}</div>
               
          </div>
         
      </>
    )
  ) : null // You might want to provide a default value if the condition is not met
}


{
  reg && reg.Modification && reg.Modification[0] ? (
    reg.Modification[0].mod_field === modField.child_sex && (
      <>
                  <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child previous name </div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_prev_firstname : "-"}</div>
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child previous surname</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_prev_surname : "-"}</div> 
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child previous middlename</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_prev_middlename : "-"}</div>
               
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child previous sex</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? getGender2(reg.Modification[0].child_prev_sex) : "-"}</div> 
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child previous age</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_prev_age : "-"}</div>
               
          </div>
          <div className="text-500 font-medium mb-2">Child  name </div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_firstname : "-"}</div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child  surname</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_surname : "-"}</div> 
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child  middlename</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_middlename : "-"}</div>
               
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child  sex</div>

              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? getGender2(reg.Modification[0].child_sex) : "-"}</div> 
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Child  age</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].child_age : "-"}</div>
               
          </div>
         
      </>
    )
  ) : null // You might want to provide a default value if the condition is not met
}
{
  reg && reg.Modification && reg.Modification[0] ? (
    reg.Modification[0].mod_field === modField.father_name && (
      <>
                  <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Father previous name </div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].father_prev_firstname : "-"}</div>
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Father previous surname</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].father_prev_surname : "-"}</div> 
          </div>
          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">Father previous middlename</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].father_prev_middlename : "-"}</div>
               
          </div>

          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">father firstname</div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].father_firstname : "-"}</div>
               
          </div>

          <div className="col-12 md:col-2 p-3">
              <div className="text-500 font-medium mb-2">father surname </div>
              <div className="text-900">{reg && reg.Modification && reg.Modification[0] ? reg.Modification[0].father_surname : "-"}</div>
          </div>
      </>
    )
  ) : null // You might want to provide a default value if the condition is not met
}   
 </div>
 {/* eslint-disable-next-line */}
      {reg &&reg.Modified_Status!== Mod.Approved&&
      <Button label="Approve" className="p-button-success my-2" loading={isEditing} onClick={()=>ed(id)} />
      }

  {/* <Button label="Query" className="p-button-warning my-2 ml-3 text-white"  /> */}
  </div>
  
      </div>
    )
}
     
export default SingleModifiedBirth






// "child_firstname": null,
// "child_middlename": null,
// "child_surname": null,
// "child_sex": null,
// "child_age": null,
// "mother_firstname": "HAPPINESS queennnnnn",
// "mother_surname": "UGBOR",
// "mother_middlename": null,
// "father_firstname": null,
// "father_surname": null,
// "father_middlename": null,
// "child_prev_firstname": null,
// "child_prev_middlename": null,
// "child_prev_surname": null,
// "child_prev_sex": null,
// "child_prev_age": null,
// "mother_prev_firstname": "HAPPINESS queen",
// "mother_prev_surname": "UGBOR",
// "mother_prev_middlename": "ugochi",
// "father_prev_firstname": null,
// "father_prev_surname": null,
// "father_prev_middlename": null,