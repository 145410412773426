/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import authFetch from "../axios";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { useDebounce } from "../helpers/hooks/useDebounce";
const AdminVerifiedReg = () => {
  const [totalRegistrars, SetTotalRegistrars] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [reg, setReg] = useState([]);
  const [search, setSearch] = useState("");
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(1);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setPage(event.page + 1);
    setBasicRows(event.rows);
  };
  const { value: debouncedValue } = useDebounce(search, 1000);

  useEffect(() => {
    const getStatz = async () => {
      try {
        const statistics = await authFetch.get("/dcr/registrar/total");
        console.log(statistics.data);
        SetTotalRegistrars(statistics.data.total_registrars);
      } catch (error) {
        console.log(error);
      }
    };
    const getNz = async () => {
      setIsLoading(true);
      try {
        const statistics = await authFetch.get(
          `/dcr/registrar/list?search=${debouncedValue}&result_per_page=${basicRows}&page=${page}`
        );
        console.log(statistics);
        setReg(statistics.data.result);
        setTotal(statistics.data?.pagination?.total);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getStatz();
    getNz();
  }, [debouncedValue, basicRows, page]);

  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`/single-registrars/${rowData.User_ID}`}
      >
        VIEW
      </Link>
    );
  };

  const renderHeader1 = () => {
    return (
      <div className="hidden md:flex justify-content-between">
        <div className="grid ">
          <div className="col-12 md:col-6">
            <InputText
              value={search}
              // onChange={onGlobalFilterChange1}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Keyword Search"
            />
          </div>
        </div>
      </div>
    );
  };
  const header1 = renderHeader1();

  return (
    <>
      <div className="grid my-3">
        <div className="col-12 " style={{ backgroundColor: "#10A6AB" }}>
          <p className="text-white m-0 text-sm font-bold">
            Total Registrars:{totalRegistrars}
          </p>
        </div>

        <div className="col-12 lg:col-12">
          <div className="card border-round shadow-2 p-3 ">
            <div className="mb-3 flex align-items-center justify-content-between p-3">
              <span className="text-xl font-medium text-900">
                {" "}
                Registrars Lists
              </span>
              <div className="flex align-items-center export-buttons"></div>
            </div>
            <DataTable
              value={reg}
              loading={isLoading}
              stripedRows
              responsiveLayout="stack"
              header={header1}
            >
              <Column field="UserName" header="UserName"></Column>
              <Column field="Email" header="email"></Column>
              <Column field="Phone_No" header="Phone_No"></Column>

              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={basicFirst}
              rows={basicRows}
              totalRecords={total}
              onPageChange={onBasicPageChange}
            ></Paginator>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminVerifiedReg;
